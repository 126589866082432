import React, { FunctionComponent, useEffect } from "react";
import "./styles.scss";

export interface PromotigoIframeInterface {
  IframeUrl?: string;
  IframeName?: string;
  formId?: string;
}

const PromotigoIframe: FunctionComponent<PromotigoIframeInterface> = ({ IframeUrl, IframeName, formId }) => {
  useEffect(() => {
    if (formId) {
      const script = document.createElement("script");
      script.id = "promotigo-bootstrapper";
      script.src = "https://plugin.promotigo.com/Scripts/forms-bootstrapperV2.js";
      script.setAttribute("data-url", "https://plugin.promotigo.com/");
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [formId]);

  useEffect(() => {
    if (formId) {
      configPromotigo();
    }
  }, [formId]);

  function scrollOnSubmit() {
    let promotigoComponent = document.getElementById("promotigoComponent");

    if (!promotigoComponent) {
      setTimeout(() => {
        scrollOnSubmit();
      }, 500);
    } else {
      promotigoComponent?.scrollIntoView({
        behavior: "smooth"
      });
    }
  }

  const configPromotigo = () => {
    if (formId && window.promotigo) {
      window.promotigo.server = "https://api2.promotigo.com/";
      window.promotigo.formId = formId;
      window.promotigo.bootstrap = true;
      window.promotigo.preSubmit = scrollOnSubmit;
      window.promotigo.renderForm("#promotigoForm");
    } else {
      setTimeout(configPromotigo, 1000);
    }
  };

  if (formId) {
    return (
      <div id="promotigoComponent" className="promotigoComponent">
        <div id="promotigoForm">Loading...</div>
      </div>
    );
  } else {
    return (
      <section>
        <iframe
          className="promotigo-iframe-block"
          src={IframeUrl}
          name={IframeName}
          title={IframeName}
          allowFullScreen
          scrolling="no"
          frameBorder="0"
        />
      </section>
    );
  }
};

export default PromotigoIframe;
